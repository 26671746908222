import React, { useState } from 'react';

import { Button } from '@src/components';
import { setVerificationModalOpen } from '@src/features/layout';
import { useAppDispatch, useAppSelector } from '@src/hooks';

const VerificationModal: React.FC = () => {
    const [inside_card, setInsideCard] = useState(false);
    const open = useAppSelector(
        (state) => state.layout?.verification_modal_open,
    );
    const text = useAppSelector(
        (state) => state.layout?.verficiation_modal_text,
    );

    const dispatch = useAppDispatch();
    const onClose = () => {
        dispatch(setVerificationModalOpen(false));
    };

    return (
        <div
            className={`min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover text-white ${
                open ? '' : 'hidden'
            }`}
            id="modal-id"
            onClick={() => !inside_card && onClose()}
        >
            <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
            <div
                className="relative max-w-md mx-auto my-auto shadow-lg bg-surface-level-one border border-border border-opacity-5 rounded p-6"
                onMouseEnter={() => setInsideCard(true)}
                onMouseLeave={() => setInsideCard(false)}
            >
                <h3 className="text-3xl mb-8">
                    <strong>{text?.title}</strong>
                </h3>

                <p className="mb-8">{text?.body}</p>
                <Button className="w-full" onClick={onClose}>
                    Done
                </Button>
            </div>
        </div>
    );
};

export default VerificationModal;
